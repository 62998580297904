<template>
  <div>
    <div class="tw-mb-5 tw-flex tw-justify-between">
      <div class="tw-flex tw-flex-wrap tw-gap-2">
        <button
          type="button"
          title="Gemeente"
          :class="['btn', mapMode === 'click' ? 'btn-primary' : 'btn-default']"
          @click="mapMode = 'click'"
        >
          <i class="fa fa-map-marker" /> Gemeente
        </button>
        <button
          type="button"
          title="Polygoon"
          :class="['btn', mapMode === 'poly' ? 'btn-primary' : 'btn-default']"
          @click="mapMode = 'poly'"
        >
          <i class="fa fa-crosshairs" /> Polygoon
        </button>
        <button
          type="button"
          title="Cirkel"
          :class="['btn', mapMode === 'circle' ? 'btn-primary' : 'btn-default']"
          @click="mapMode = 'circle'"
        >
          <i class="fa fa-circle-o" /> Cirkel
        </button>
        <button
          type="button"
          title="Help"
          :class="['btn', mapMode === 'help' ? 'btn-info' : 'btn-default' ]"
          @click="mapMode = 'help'"
        >
          <i class="fa fa-question" /> Help
        </button>
      </div>
      <div class="tw-flex tw-flex-wrap tw-gap-2">
        <button
          type="button"
          title="Aanpassen"
          :class="['btn', mapMode === 'select' ? 'btn-primary' : 'btn-default']"
          @click="mapMode = 'select'"
        >
          <i class="fa fa-pencil" />
        </button>
        <button
          v-show="mapMode === 'select' && hasSelection"
          type="button"
          title="Verwijderen"
          class="btn btn-danger"
          @click="removeSelectedFeatures"
        >
          <i class="fa fa-trash" />
        </button>
      </div>
    </div>

    <div id="map" v-show="mapMode !== 'help'" />

    <div v-show="mapMode === 'help'">
      <p>Dit scherm zal je helpen om de kaart te gebruiken om een zoekopdracht aan te maken. Via dit scherm kan je snel
        plaatsen selecteren op kaart, meerdere polygonen tekenen en cirkels trekken op kaart.</p>

      <div class="row">
        <div class="col-sm-4">
          <h2>Gemeenten selecteren</h2>
          <p>
            Met de knop <a class="btn btn-primary"><i class="fa fa-map-marker"></i> Gemeente</a> kan je snel meerdere
            gemeenten selecteren op kaart.
          </p>
          <p>De gemeenten die je hebt aangeduid worden ook zichtbaar op het algemene scherm met zoekdracht criteria.</p>
        </div>
        <div class="col-sm-4">
          <h2>Polygonen tekenen</h2>
          <p>
            Met de knop <a class="btn btn-primary"><i class="fa fa-crosshairs"></i> Polygoon</a> kan je meerdere
            polygonen tekenen op kaart. Een polygoon is duur woord voor veelhoek, je duid met de muis meerdere punten op kaart aan
            die een veelhoek vormen.
          </p>
          <p>
            Alle panden die liggen in de polygonen van deze zoekopdracht komen in aanmerking. Dit is een handige manier
            om het aanbod van panden te beperken tot een bepaalde regio, bijvoorbeeld een industriezone, een villawijk,
            enz.
          </p>
        </div>
        <div class="col-sm-4">
          <h2>Cirkels trekken</h2>
          <p>
            Met de knop <a class="btn btn-primary"><i class="fa fa-circle-o"></i> Cirkel</a> kan je meerdere cirkels trekken op de kaart.
          </p>
          <p>
            Net zoals bij een polygoon zullen alle panden die binnen deze cirkel liggen worden opgenomen in de zoekopdracht.
          </p>
        </div>
      </div>

      <div>
        <h2>Aanpassen en verwijderen van selecties</h2>
        <p>
          De getekende <strong>polygonen</strong> en <strong>cirkels</strong> kunnen bewerkt worden met behulp van de <a class="btn btn-default"><i class="fa fa-pencil" /></a>
          knop rechts bovenaan.
        </p>
        <p>
          Eens het potloodje geactiveerd is, kan een polygoon of cirkel aangeklikt worden. Vervolgens kan de <a class="btn btn-danger"><i class="fa fa-trash" /></a>
          knop die verschijnt naast het potloodje gebruikt worden om de geselecteerde polygoon of cirkel te verwijderen.
        </p>
        <p>
          Cirkels kunnen verplaatst worden door in het midden van de cirkel te klikken en van daaruit de vorm te verplaatsen.
          Polygonen kunnen bewerkt worden door een van de hoekpunten aan te klikken en te verslepen.
        </p>
        <p>
          Via de knop <a class="btn btn-primary"><i class="fa fa-map-marker"></i> Gemeente</a> kunnen <strong>gemeentes</strong> uit de selectie verwijderd worden. Klik hiervoor eenmaal op een geselecteerde (rode) gemeente.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Map from 'ol/Map.js'
import View from 'ol/View.js'
import Polygon from 'ol/geom/Polygon'
import Circle from 'ol/geom/Circle'
import Feature from 'ol/Feature'
import { click, pointerMove } from 'ol/events/condition.js'
import GeoJSON from 'ol/format/GeoJSON.js'
import Select from 'ol/interaction/Select.js'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js'
import VectorSource from 'ol/source/Vector.js'
import { Draw, Modify, Snap } from 'ol/interaction.js'
import { Fill, Stroke, Style, Text } from 'ol/style.js'
import XYZ from 'ol/source/XYZ'
import { toLonLat, fromLonLat, getPointResolution } from 'ol/proj'
import { getCityById } from '@/services/apiService'

import { v4 as uuid4 } from 'uuid'

export default {
  name: 'SearchMap',
  props: ['geoSearch'],
  data () {
    return {
      rendered: false,
      mapMode: 'click',
      map: null,
      cityLayer: null,
      searchLayer: new VectorSource(),
      cities: [],
      selectedFeatures: [],
      selectCityClick: null,
      selectFeatureClick: null,
      featureStyle: new Style({
        stroke: new Stroke({
          color: 'gray',
          width: 2
        }),
        fill: new Fill({
          color: 'rgba(20, 20, 20, 0.2)'
        })
      }),
      selectedFeatureStyle: new Style({
        stroke: new Stroke({
          color: 'red',
          width: 2
        }),
        fill: new Fill({
          color: 'rgba(200, 20, 20, 0.2)'
        })
      })
    }
  },
  computed: {
    location () {
      return this.map.getView().getCenter()
    },
    zoom () {
      return this.map.getView().getZoom()
    },
    hasSelection () {
      return this.selectFeatureClick?.getFeatures().getLength() > 0
    }
  },
  watch: {
    mapMode () {
      if (this.mapMode === 'select') {
        this.activateModifySelection()
      }
      if (this.mapMode === 'click') {
        this.activateClickSelection()
      }
      if (this.mapMode === 'poly') {
        this.activatePolygonDraw()
      }
      if (this.mapMode === 'circle') {
        this.activateCircleDraw()
      }
    }
  },
  methods: {
    removeSelectedFeatures () {
      const features = this.selectFeatureClick.getFeatures()
      features.forEach(feature => {
        this.searchSource.removeFeature(feature)
      })
      this.selectFeatureClick.getFeatures().clear()
    },
    // Clear all features drawn and selected
    clear () {
      this.cities = []
      this.selectedFeatures = []
      if (this.searchSource) {
        this.searchSource.clear()
      }
    },
    getCoordinates () {
      const features = this.searchLayer.getSource().getFeatures()
      const output = []
      features.forEach((feature) => {
        let coords = feature.getGeometry().getCoordinates()

        if (!coords) {
          const lonLat = toLonLat([feature.getGeometry().getCenter()[0], feature.getGeometry().getCenter()[1]])
          output.push({
            type: 'circle',
            lon: lonLat[0],
            lat: lonLat[1],
            radius: this.getTrueRadiusInMeters(feature.getGeometry())
          })
        } else {
          const latLon = []
          coords[0].forEach(coord => {
            const c = toLonLat(coord)
            latLon.push([c[1], c[0]])
          })
          coords = toLonLat(coords, 'EPSG:3857')
          output.push({
            type: 'polygon',
            coordinates: latLon
          })
        }
      })
      return output
    },
    clearInteractions () {
      if (!this.map) return

      this.map.removeInteraction(this.draw)
      this.map.removeInteraction(this.draw)
      this.map.removeInteraction(this.modify)
      this.map.removeInteraction(this.selectCityClick)
      this.map.removeInteraction(this.selectFeatureClick)
      this.map.removeInteraction(this.selectPointerMove)
    },
    setupModify () {
      this.modify = new Modify({
        features: this.selectPointerMove.getFeatures()
      })
    },
    setupSelect () {
      this.selectPointerMove = new Select({
        condition: pointerMove,
        layers: [this.searchLayer]
      })
      this.map.addInteraction(this.selectPointerMove)
    },
    activateModifySelection () {
      this.clearInteractions()
      this.setupSelect()

      const features = this.selectPointerMove.getFeatures()

      this.modify = new Modify({
        source: this.searchSource,
        features
      })

      this.modify.on('modifyend', event => {
        this.searchSource.forEachFeature(feature => {
          const geometry = feature.getGeometry()
          if (geometry.getType() !== 'Circle') return
          const circleStyle = this.getCircleStyleWithRadius(geometry)
          feature.setStyle(circleStyle)
        })
      })

      this.map.addInteraction(this.modify)

      this.selectFeatureClick = new Select({
        condition: click,
        layers: [this.searchLayer],
        style: new Style({ fill: new Fill({ color: 'rgba(0, 153, 255, 0.5)' }) })
      })
      this.map.addInteraction(this.selectFeatureClick)
    },
    activateClickSelection () {
      this.clearInteractions()

      this.selectPointerMove = new Select({
        condition: pointerMove,
        layers: [this.cityLayer]
      })
      this.map.addInteraction(this.selectPointerMove)

      this.selectCityClick = new Select({
        condition: click
      })
      this.map.addInteraction(this.selectCityClick)

      this.selectCityClick.on('select', async event => {
        const selectedFeature = event.target.getFeatures().getArray()[0]
        const props = selectedFeature.getProperties()
        const idx = this.selectedFeatures.indexOf(props.id)
        if (idx > -1) {
          this.selectedFeatures.splice(idx, 1)
          this.cities = this.cities.filter(city => city.id !== props.id)
        } else {
          this.selectedFeatures.push(props.id)
          const response = await getCityById(props.id)
          const city = response?.data
          if (city) this.cities.push(city)
        }
        event.target.getFeatures().clear() // clear selection
        this.cityLayer.changed() // Force layer redraw
        this.$emit('selected', this.cities)
      })
    },
    activatePolygonDraw () {
      this.clearInteractions()
      this.setupModify()

      this.draw = new Draw({
        source: this.searchSource,
        type: 'Polygon'
      })

      // Set a feature ID when finish drawing the feature
      this.draw.on('drawend', event => {
        const id = uuid4()
        event.feature.setId(id)
        this.$emit('drawn')
      })

      this.map.addInteraction(this.draw)
    },

    getTrueRadiusInMeters (circle) {
      const sourceProjection = this.map.getView().getProjection()
      const pointResolution = getPointResolution(sourceProjection, 1, circle.getCenter())
      const trueRadius = circle.getRadius() * pointResolution
      return trueRadius
    },

    getProjectedRadius (coords, radius) {
      const sourceProjection = this.map.getView().getProjection()
      const pointResolution = getPointResolution(sourceProjection, 1, coords)
      const projectedRadius = radius / pointResolution
      return projectedRadius
    },

    activateCircleDraw () {
      this.clearInteractions()

      this.draw = new Draw({
        source: this.searchSource,
        type: 'Circle'
      })

      // Set a feature ID when finish drawing the feature
      this.draw.on('drawend', event => {
        const id = uuid4()
        event.feature.setId(id)

        const feature = event.feature
        const circle = feature.getGeometry()
        const circleStyle = this.getCircleStyleWithRadius(circle)
        feature.setStyle(circleStyle)
        this.$emit('drawn')
      })

      this.map.addInteraction(this.draw)
    },
    setupGeosearchFeatures () {
      if (!this.geoSearch?.length) return

      this.geoSearch.forEach((feature) => {
        if (feature.type === 'polygon') {
          const coords = []
          feature.coordinates.forEach((coord) => {
            coords.push(fromLonLat([coord[1], coord[0]]))
          })
          const poly = new Polygon([coords])
          this.searchSource.addFeature(new Feature({
            type: 'Polygon',
            geometry: poly
          }))
        } else if (feature.type === 'circle') {
          const coords = fromLonLat([feature.lon, feature.lat])
          const projectedRadius = this.getProjectedRadius(coords, feature.radius)
          const circle = new Circle(coords, projectedRadius)
          const circleStyle = this.getCircleStyleWithRadius(circle)
          const circleFeature = new Feature({
            type: 'Circle',
            geometry: circle
          })
          circleFeature.setStyle(circleStyle)
          this.searchSource.addFeature(circleFeature)
        }
      })
    },
    async render () {
      if (this.rendered) {
        // If the map has been rendered we skip the initialization below and just trigger a change of the citylayer
        // and a redraw of the geosearch features (polygon and circle).
        this.cityLayer.changed()
        this.setupGeosearchFeatures()
        return
      } else {
        this.rendered = true
      }
      // Perform initialization of all sources and layers
      return await setTimeout(() => {
        // Create VectorSource for cities
        const source = new VectorSource({
          url: '/json/cities.json',
          format: new GeoJSON()
        })

        //  Create vectorylayer for cities
        this.cityLayer = new VectorLayer({
          source,
          style: (feature) => {
            const cities = feature.getProperties().city_ids
            const intersect = this.selectedFeatures.filter(value => cities.includes(value))
            if (intersect.length > 0) {
              return this.selectedFeatureStyle
            }
            return this.featureStyle
          }
        })

        this.searchSource = new VectorSource()
        this.searchLayer = new VectorLayer({
          source: this.searchSource,
          style: new Style({
            fill: new Fill({
              color: 'rgba(255, 46, 67, 0.47)'
            }),
            stroke: new Stroke({
              color: '#ffcc33',
              width: 2
            })
          })
        })

        this.map = new Map({
          target: 'map',
          layers: [
            new TileLayer({
              source: new XYZ({
                url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              })
            }),
            this.cityLayer,
            this.searchLayer
          ],
          view: new View({
            center: [400651, 6617176],
            zoom: 9
          })
        })

        this.snap = new Snap({ source: this.searchSource })
        this.map.addInteraction(this.snap)

        this.setupGeosearchFeatures()

        this.activateClickSelection()
      }, 500)
    },
    getCircleStyleWithRadius (circle) {
      const radiusMeters = this.getTrueRadiusInMeters(circle)
      const radiusKilometers = radiusMeters / 1000

      // Create a style for the circle with a label
      const circleStyle = new Style({
        fill: new Fill({
          color: 'rgba(255, 46, 67, 0.47)'
        }),
        stroke: new Stroke({
          color: '#ffcc33',
          width: 2
        }),
        text: new Text({
          text: 'Radius: ' + radiusKilometers.toFixed(2) + ' km',
          textAlign: 'center',
          offsetX: 0,
          offsetY: 0,
          font: '14px Open Sans',
          fill: new Fill({
            color: 'white'
          }),
          stroke: new Stroke({
            color: '#676a6c', // tg-color
            width: 2
          })
        })
      })

      return circleStyle
    },

    /** Retrieves the cities for a HMODH from the map. If there are any child cities for a selected city on the map,
     * then the child cities will be retrieved. If no child cities are present, then the selected city will be retrieved
     * */
    getCities () {
      const cities = this.cities

      const citiesWithChildren = []
      cities.forEach(city => {
        if (city.children?.length) citiesWithChildren.push(...city.children)
        else citiesWithChildren.push(city)
      })
      return citiesWithChildren
    }
  }
}
</script>

<style scoped>
  @import url(~ol/ol.css);
</style>
